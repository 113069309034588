@font-face {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/Sarabun/Sarabun-Regular.ttf");
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.span {
    font-family: "Sarabun";
    margin: 0;
}